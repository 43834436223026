import { useDispatch, useSelector } from 'react-redux';
import { closeIFrame, openIFrame } from '../store/slices/iframeSlice';
import type { RootState } from '../store/store';

export const useIFrameModal = () => {
    const dispatch = useDispatch();
    const { isOpen, url, title } = useSelector((state: RootState) => state.iframe);

    const open = (url: string, title?: string) => {
        dispatch(openIFrame({ url, title }));
    };

    const close = () => {
        dispatch(closeIFrame());
    };

    return {
        isOpen,
        url,
        title,
        open,
        close,
    };
};