import { useCallback, useEffect, useState } from 'react';
import { useComponentModal } from 'src/hook/useComponentModal';
import useFetch from 'src/hook/useFetch';
import { useLearnWizard } from 'src/hook/useLearnWizard';
import { WordData } from 'src/model/WordData';
import { GetWordDataFileUrl } from 'src/utils/URLUtils';
import { useLearning } from '../../../hook/useLearning';
import useUser from '../../../hook/useUser';
import { Actions, LearningArray, LearnWizardStep } from '../../../model/enum/enums';
import { Word } from '../../../model/Word';
import styles from './DefinitionFooter.module.scss';


type DefinitionFooterProps = {
    word: Word;
    moveNext: () => void;
}

export const DefinitionFooter = ({ word, moveNext }: DefinitionFooterProps) => {
    const { addWord, removeWord } = useLearning();
    const { saveProgress } = useUser();
    const { learningInfo } = useLearning();
    const [existWord, setExistWord] = useState<string>("");
    const { open } = useLearnWizard();
    const { send } = useFetch<WordData>();
    const { close } = useComponentModal();

    const handleShouldLearn = useCallback(() => {
        addWord(LearningArray.UnknownWordIds, word.ID);
        addWord(LearningArray.ToLearn, word.ID);
        saveProgress(Actions.Learn, word.ID);
        moveNext();
    }, [word, addWord, saveProgress, moveNext]);

    const handleAlreadyKnew = useCallback(() => {
        addWord(LearningArray.KnownWordIds, word.ID);
        removeWord(LearningArray.UnknownWordIds, word.ID);
        removeWord(LearningArray.ToLearn, word.ID);
        saveProgress(Actions.Knew, word.ID);
        moveNext();
        close();
    }, [word, addWord, saveProgress, moveNext, removeWord, close]);

    const handleRemove = useCallback(() => {
        if (existWord === Actions.Knew)
            removeWord(LearningArray.KnownWordIds, word.ID);
        else {
            removeWord(LearningArray.UnknownWordIds, word.ID);
            removeWord(LearningArray.ToLearn, word.ID);
        }

    }, [word, removeWord, existWord]);

    const handleLearnNowContinue = useCallback(async () => {
        if (existWord === Actions.Knew) {
            moveNext();
            close();
        } else {
            const { response } = await send(GetWordDataFileUrl(word.ID));
            if (!response) return;
            open(LearnWizardStep.First, { word, wordData: response });
        }
    }, [word, open, send, existWord, moveNext]);

    useEffect(() => {
        if (learningInfo?.KnownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Knew)
        else if (learningInfo?.UnknownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Learn)
        else
            setExistWord('');
    }, [learningInfo?.KnownWordIds, learningInfo?.UnknownWordIds, word.ID]);


    return (
        <section className={styles.definitionFooter}>
            {!existWord && <div className={styles.buttonSection}>
                <button aria-hidden="true" tabIndex={-1} onClick={() => handleShouldLearn()} >Should learn</button>
                <button aria-hidden="true" tabIndex={-1} onClick={() => handleAlreadyKnew()} className='turquoiseButton'>Already knew</button>
            </div>}
            {existWord && <div className={styles.selectSection}>
                <div className={styles.labelSection}>
                    <span className={styles.label}>Your knowledge:</span>
                    <div onClick={handleRemove}
                        className={`${styles.styledButton} ${existWord === Actions.Knew ? styles.knew : styles.learn}`}>
                        {existWord === Actions.Knew ? 'Already Knew' : 'Should Learn'} ▼
                    </div>
                </div>
                <button aria-hidden="true"
                    tabIndex={-1} className={styles.learnNowButton} onClick={handleLearnNowContinue}>
                    {existWord === Actions.Knew ? 'Continue' : 'Learn Now'}
                </button>
            </div>}
        </section >
    );
};
