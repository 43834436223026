import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from '../model/enum/enums';
import { WordTranslate } from '../model/Word';
import { fetchTranslates } from '../store/slices/wordTranslateSlice';
import { AppDispatch, RootState } from '../store/store';

interface useWordTranslateReturn {
    translates: WordTranslate;
    status: LoadingStatus;
    error: string | null;
    fetchTranslates: (languageCode?: string, forceRefresh?: boolean) => Promise<void>;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    getTranslateBySenseId: (senseId: string) => string;
}

export const useWordTranslate = (languageCode?: string, autoLoad: boolean = true): useWordTranslateReturn => {
    const dispatch = useDispatch<AppDispatch>();
    const initialLoadDone = useRef(false);

    const translates = useSelector((state: RootState) => state.translates.translates);
    const status = useSelector((state: RootState) => state.translates.status);
    const error = useSelector((state: RootState) => state.translates.error);

    const fetchData = useCallback(async (languageCode?: string, forceRefresh: boolean = false) => {
        if (!languageCode || languageCode === 'en') return; // Prevent multiple fetches
        try {
            await dispatch(fetchTranslates({ languageCode, forceRefresh })).unwrap();
            initialLoadDone.current = true;
        } catch (error) {
            console.error('Error fetching translates:', error);
        }
    }, [dispatch]);

    useEffect(() => {
        if (autoLoad && status === LoadingStatus.IDLE && !Object.keys(translates).length && !initialLoadDone.current) {
            fetchData(languageCode);
        }
    }, [autoLoad, status, fetchData, languageCode, translates]);

    const getTranslateBySenseId = useCallback((senseId: string) => {
        return translates[senseId];
    }, [translates]);

    return {
        translates,
        status,
        error,
        fetchTranslates: fetchData,
        isLoading: status === LoadingStatus.LOADING,
        isError: status === LoadingStatus.FAILED,
        isSuccess: status === LoadingStatus.SUCCEEDED,
        getTranslateBySenseId
    };
};