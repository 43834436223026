import { useEffect, useMemo, useState } from 'react';
import { WordList } from '../model/WordList';
import useFetch from './useFetch';

export const useWordListData = (wordList?: WordList, isCustomList: boolean = false) => {
    const [wordListData, setWordListData] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const { send } = useFetch<any>();
    const memoizedData = useMemo(() => wordListData, [wordListData]);

    useEffect(() => {
        const fetchData = async () => {
            if (!wordList?.WordsUrl) return;
            setIsLoading(true);
            try {
                const { response } = await send(wordList.WordsUrl);

                const rawData = isCustomList ? response : response?.uw;
                const numberArray = rawData
                    ?.split(',')
                    ?.map((id: string) => parseInt(id.trim(), 10))
                    ?.filter((id: number) => !isNaN(id)) ?? [];

                setWordListData(numberArray);
            } catch (err) {
                setError(err instanceof Error ? err : new Error('Failed to fetch word list'));
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [wordList?.WordsUrl, send, isCustomList]);



    return {
        wordListData: memoizedData,
        isLoading,
        error
    };
};