import { FC } from 'react';
import { Word } from '../../../model/Word';
import { Sense, WordData } from '../../../model/WordData';
import ProTips from '../../ui/GlobalComponents/ProTips/ProTips';
interface SecondWizardProps {
    word: Word;
    wordData: WordData;
}

const SecondWizard: FC<SecondWizardProps> = ({ word, wordData }) => {
    return (
        <ProTips word={word} sense={wordData?.Senses?.find(sense => sense.id === word.PrimarySenseId) as Sense} />
    );
};

export default SecondWizard;
