import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCountries } from 'src/hook/useCountries';
import useFetch from 'src/hook/useFetch';
import { useLearning } from '../../hook/useLearning';
import useUser from '../../hook/useUser';
import { useWords } from '../../hook/useWords';
import { useWordTranslate } from '../../hook/useWordTranslate';
import { LanguageLevel } from '../../model/enum/enums';
import { GetRazorSiteUrl, GetStartPageUrl } from '../../utils/URLUtils';
import ErrorMessage from '../ui/Error/Error';
import Spinner from '../ui/Spinner/Spinner';
import { HomePage } from './HomePage';

const Load: React.FC = () => {
    const isInitializing = useRef(false);
    const { setUserInfo } = useUser();
    const { setLearningInfo } = useLearning();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const token = queryParams.get('t');
    const navigate = useNavigate();
    const { fetchWords } = useWords(false);
    const { fetchCountries } = useCountries(false);
    const { fetchTranslates } = useWordTranslate(undefined, false);
    const { sendChain } = useFetch<any>();

    const initializeApp = async () => {
        if (isInitializing.current) return;

        try {
            isInitializing.current = true;
            setIsLoading(true);

            const results = await sendChain([
                { url: GetRazorSiteUrl('settings', token!) },
                { url: GetRazorSiteUrl('learning', token!) }
            ]);

            const dataUser = results[0].response;
            const dataLearning = results[1].response;

            setUserInfo({ ...dataUser, Token: token! });
            setLearningInfo(dataLearning);


            fetchWords(false);
            fetchCountries(false);
            fetchTranslates(dataUser.NativeLanguageCode, false);

            const home = dataLearning?.LanguageLevel === LanguageLevel.Basic ? 'lessons' : 'wordlists';
            navigate(`/${home}`);
        } catch (error) {
            window.location.href = GetStartPageUrl();
            setError(error as string);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (token && !isInitializing.current) {
            initializeApp();
        }
    }, []);

    if (isLoading) return <Spinner />;
    else if (error) return <ErrorMessage message={error} />;
    else return <HomePage />;
};

export default memo(Load);