import { Component } from 'react';
import { Provider } from 'react-redux';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Layout from './components/Layout/Layout';
import Load from './components/Load/Load';
import { store } from './store/store';
import './styles/reactSlickCarousel.scss';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Layout>
          <Load />
        </Layout>
      </Provider>
    );
  }
}

export default App;