import { useState } from 'react';
import { useIFrameModal } from '../../../../hook/useIFrameModal';
import { useLearning } from '../../../../hook/useLearning';
import useUser from '../../../../hook/useUser';
import { Period } from '../../../../model/enum/enums';
import { GetRazorSiteUrl } from '../../../../utils/URLUtils';
import Charts from '../Charts/Charts';
import styles from './ProgressContent.module.scss';

const ProgressContent = () => {
    const [period, setPeriod] = useState(Period.Daily);
    const { learningInfo } = useLearning();
    const myProgress = learningInfo?.MyAccount?.MyProgress;
    const { open } = useIFrameModal();
    const { userInfo } = useUser();

    return (
        <div className={styles.progressContent}>
            <div className={styles.periodSelectorContainer}>
                <div className={styles.periodSelector}>
                    <span onClick={() => setPeriod(Period.Daily)} className={styles.goalText + ' ' + (period === Period.Daily ? styles.active : '')}>Daily</span>
                    <span onClick={() => setPeriod(Period.Weekly)} className={styles.goalText + ' ' + (period === Period.Weekly ? styles.active : '')}>Weekly</span>
                    <span onClick={() => setPeriod(Period.Monthly)} className={styles.goalText + ' ' + (period === Period.Monthly ? styles.active : '')}>Monthly</span>
                </div>
                <span className={styles.goalText + ' ' + styles.active}
                    onClick={() => open(GetRazorSiteUrl('onboarding/daily-goal', userInfo?.Token))}>
                    {myProgress?.DailyGoalText}
                </span>
            </div>
            <Charts period={period} />
        </div>
    );
};

export default ProgressContent;