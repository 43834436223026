
import AudioPlayer from "src/components/ui/GlobalComponents/AudioPlayer/AudioPlayer";
import VolumeSection from "src/components/ui/GlobalComponents/VolumeSection/Volumesection";
import { useWordTranslate } from "src/hook/useWordTranslate";
import { HighlightWord } from "src/utils/TextUtils";
import { Word } from "../../../../model/Word";
import { Sense } from "../../../../model/WordData";
import styles from './SenseItem.module.scss';
import useUser from "src/hook/useUser";

interface SenseProps {
    word: Word;
    sense: Sense;
}

const SenseItem = ({ word, sense }: SenseProps) => {
    const { userInfo } = useUser();
    const { getTranslateBySenseId } = useWordTranslate(userInfo?.NativeLanguageCode);

    return (
        <div className={styles.senseItem}>
            <h1>{word.Text}</h1>
            <div className={styles.volumeSection}>
                <VolumeSection word={word} />
                <span className={styles.type}>{sense?.ty}</span>
            </div>
            <hr />
            <div className={styles.definition}>{sense?.de}</div>
            <div className={styles.example}>
                {HighlightWord(sense?.ex, word.Text)}
            </div>

            <div className={styles.audioPlayer}>
                <AudioPlayer text={`${sense?.de}. for example. ${sense?.ex}`} />
                <div className={styles.translate}>{getTranslateBySenseId(sense?.id)}</div>
            </div>
        </div>
    );
};

export default SenseItem;