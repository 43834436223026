import { FC } from 'react';
import { useComponentModal } from 'src/hook/useComponentModal';
import useUser from '../../../../../hook/useUser';
import { useWordTranslate } from '../../../../../hook/useWordTranslate';
import { AnimationType, Voice } from '../../../../../model/enum/enums';
import { Word } from '../../../../../model/Word';
import ImageSection from '../../../../ui/GlobalComponents/ImageSection/ImageSection';
import VolumeButton from '../../../../ui/GlobalComponents/VolumeButton/VolumeButton';
import { ButtonSection } from './ButtonSection/ButtonSection';
import styles from './SlideItem.module.scss';


interface SlideItemProps {
    word: Word;
    moveNext: () => void;
}

export const SlideItem: FC<SlideItemProps> = ({ word, moveNext }) => {
    const { userInfo, isNonEnglishNativeLanguage } = useUser();
    const { getTranslateBySenseId } = useWordTranslate(userInfo?.NativeLanguageCode);
    const { open } = useComponentModal();

    return (
        <section className={styles.slideItem}>
            <ImageSection word={word} hasRank={false} />
            <div className={styles.container}>
                <div className={styles.word}>
                    <div className={styles.volumeSection}>
                        <h1>{word.Text}</h1>
                        <VolumeButton word={word} />
                    </div>
                    {word.BritishPhonetic && <div className={styles.rank}>
                        <span>/{userInfo?.Voice === Voice.gb ? word.BritishPhonetic : word.AmericanPhonetic}/</span>
                        <span>#{word.Rank}</span>
                    </div>}
                </div>
                {isNonEnglishNativeLanguage() && <p className={styles.translate}>
                    {getTranslateBySenseId(word.PrimarySenseId)}
                </p>}
                <div className={styles.primaryMeaning}>
                    {word.PrimaryMeaning}
                    <span onClick={() => open('Definition', { word: word, moveNext: moveNext }, AnimationType.SlideUp)}
                        className={styles.learnMore}>Learn more...</span>
                </div>
                <ButtonSection word={word} moveNext={moveNext} />
            </div>
        </section>
    );
};
