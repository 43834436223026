import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLocation } from '../../../../utils/URLUtils';
import styles from './DiscoverPageHeader.module.scss';
import ViewMenu from './ViewMenu/ViewMenu';

interface DiscoverPageHeaderProps {
    onViewChange: (view: 'cards' | 'tags' | 'list') => void;
}

export const DiscoverPageHeader = ({ onViewChange }: DiscoverPageHeaderProps) => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const viewMenuRef = useRef<HTMLDivElement>(null);

    return (
        <section className={styles.discoverPageHeader}>
            <img className={styles.backIcon} onClick={() => navigate(-1)}
                src={IconLocation('chevron-left')} alt='back' />
            <h1>Knowledge Map</h1>
            <div ref={viewMenuRef} className={styles.iconGroup} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <img className={styles.menuIcon}
                    src={IconLocation('cards_view')} alt='menu' />
                <img className={styles.downIcon}
                    src={IconLocation('chevron-down')} alt='down' />
            </div>
            <ViewMenu
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                trigger={viewMenuRef.current}
                onViewChange={onViewChange}
            />
        </section>
    );
};
