import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'src/model/enum/enums';
import { Word, WordType } from '../model/Word';
import { fetchWords } from '../store/slices/wordsSlice';
import { AppDispatch, RootState } from '../store/store';

interface UseWordsReturn {
  words: Word[];
  status: LoadingStatus;
  error: string | null;
  fetchWords: (forceRefresh?: boolean) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  getWordById: (id: number) => Word | undefined;
  getWordByText: (text: string) => Word | undefined;
  getWordByRank: (rank: number, type?: WordType) => Word | undefined;
  searchWords: (query: string) => Word[];
  getWordsByRange: (range?: number) => Word[];
  getWordsByWordList: (filterWords: number[], range?: number) => Word[];
}

export const useWords = (autoLoad: boolean = true): UseWordsReturn => {
  const dispatch = useDispatch<AppDispatch>();
  const initialLoadDone = useRef(false);

  const words = useSelector((state: RootState) => state.words.words);
  const status = useSelector((state: RootState) => state.words.status);
  const error = useSelector((state: RootState) => state.words.error);

  const fetchData = useCallback(async (forceRefresh?: boolean) => {
    if (initialLoadDone.current) return;


    try {
      await dispatch(fetchWords({ forceRefresh })).unwrap();
      initialLoadDone.current = true;
    } catch (error) {
      console.error('Error fetching words:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (autoLoad && status === LoadingStatus.IDLE && !words.length && !initialLoadDone.current) {
      fetchData();
    }
  }, [autoLoad, status, fetchData, words]);

  const getWordByRank = useCallback((rank: number, type: WordType = WordType.Word) => {
    return words.find(word => word.Rank === rank && word.Type === type);
  }, [words]);

  const getWordById = useCallback((id: number) => {
    return words.find(word => word.ID === id);
  }, [words]);

  const getWordByText = useCallback((text: string) => {
    return words.find(word => word.Text.toLowerCase() === text.toLowerCase());
  }, [words]);

  const searchWords = useCallback((query: string) => {
    const lowercaseQuery = query.toLowerCase();
    return words.filter(word =>
      word.Text.toLowerCase().includes(lowercaseQuery) ||
      word.AllForms.some(form => form.toLowerCase().includes(lowercaseQuery))
    );
  }, [words]);

  const getWordsByRange = useCallback((range: number = 1) => {
    const startRank = ((range - 1) * 100) + 1;
    const endRank = startRank + 99;

    return words
      .filter(word => {
        const rank = word.Rank;
        return rank >= startRank && rank <= endRank && word.Type === WordType.Word;
      })
      .sort((a, b) => a.Rank - b.Rank);
  }, [words]);

  const getWordsByWordList = useCallback((filterWords: number[], range: number = 0) => {
    if (!filterWords || filterWords.length === 0) return [];

    console.log('filterWords', filterWords);
    let rangeIds = filterWords;
    if (range !== 0) {
      const startIndex = (range - 1) * 100;
      const endIndex = startIndex + 100;
      // Get the IDs for the current range
      rangeIds = filterWords.slice(startIndex, endIndex);
    }

    // Filter and sort words based on the IDs
    const filteredWords = words
      .filter(word => rangeIds.includes(word.ID))
      .sort((a, b) => {
        const indexA = filterWords.indexOf(a.ID);
        const indexB = filterWords.indexOf(b.ID);
        return indexA - indexB;
      });

    return range === 0 ? filteredWords.slice(0, 100) : filteredWords;
  }, [words]);

  return {
    words,
    status,
    error,
    fetchWords: fetchData,
    isLoading: status === LoadingStatus.LOADING,
    isError: status === LoadingStatus.FAILED,
    isSuccess: status === LoadingStatus.SUCCEEDED,
    getWordById,
    getWordByText,
    getWordByRank,
    searchWords,
    getWordsByRange,
    getWordsByWordList
  };
};