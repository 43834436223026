import { useEffect, useMemo, useState } from 'react';
import { useLearning } from '../../../hook/useLearning';
import { useSocial } from '../../../hook/useSocial';
import useUser from '../../../hook/useUser';
import { FriendSummaryData } from '../../../model/FriendSummaryData';
import { FriendFooter } from './FriendFooter/FriendFooter';
import styles from './Friends.module.scss';
import { FriendsItem } from './FriendsItem/FriendsItem';

const Friends = () => {
    const { learningInfo } = useLearning();
    const { socialInfo, fetchSocialInfo } = useSocial();
    const { userInfo } = useUser();
    const [selectedTab, setSelectedTab] = useState('friends');
    const [friendsWithYou, setFriendsWithYou] = useState<FriendSummaryData[]>([]);
    const [peopleNearByWithYou, setPeopleNearByWithYou] = useState<FriendSummaryData[]>([]);
    const currentWeekProgress = learningInfo?.MyAccount?.MyProgress?.Weekly?.Items[3]?.Value;

    const you = useMemo(() => ({
        Handle: '',
        Avatar: learningInfo?.MyAccount?.Summary?.ImageUrl,
        DisplayName: 'You',
        SubscriptionId: '',
        CurrentWeekProgress: currentWeekProgress || 0,
    }), [learningInfo?.MyAccount?.Summary?.ImageUrl, currentWeekProgress]);


    useEffect(() => {
        const fetchData = async () => {
            if (!socialInfo && userInfo?.UserId) {
                await fetchSocialInfo(userInfo?.UserId!, userInfo?.CountryCode);
            }
            const friends = socialInfo?.Friends
                ? [...socialInfo.Friends, you].sort((a, b) => b.CurrentWeekProgress - a.CurrentWeekProgress)
                : [you];

            const people = socialInfo?.PeopleNearBy
                ? [...socialInfo?.PeopleNearBy, you].sort((a, b) => b.CurrentWeekProgress - a.CurrentWeekProgress)
                : [you];

            setFriendsWithYou(friends);
            setPeopleNearByWithYou(people);

        };

        fetchData();
    }, [fetchSocialInfo, socialInfo, userInfo, you]);

    return (
        <section className={styles.friends}>
            <h2>Last 7 days</h2>
            <div className={styles.friendsContainer}>
                <div className={styles.tabsContainer}>
                    <div className={styles.tab + ' ' + (selectedTab === 'friends' ? styles.active : '')}
                        onClick={() => setSelectedTab('friends')}>Friends League</div>
                    <div className={styles.tab + ' ' + (selectedTab === 'peopleNearBy' ? styles.active : '')}
                        onClick={() => setSelectedTab('peopleNearBy')}>People Nearby</div>
                </div>
                <hr className={styles.thick} />
                <div className={styles.friendsList}>
                    {selectedTab === 'friends' ? friendsWithYou?.map((friend, index) => (
                        <div key={friend.Handle}>
                            <FriendsItem friend={friend} index={index} />
                            <hr />
                        </div>
                    )) : peopleNearByWithYou?.map((friend, index) => (
                        <div key={friend.Handle}>
                            <FriendsItem friend={friend} index={index} />
                            <hr />
                        </div>
                    ))}
                    <hr />
                </div>
            </div>
            <FriendFooter selectedTab={selectedTab} />

        </section>
    );
};

export default Friends;