import { useEffect, useState } from 'react';
import { useWordData } from 'src/hook/useWordData';
import { Word } from 'src/model/Word';
import { Sense } from 'src/model/WordData';
import ProTips from '../ui/GlobalComponents/ProTips/ProTips';
import Spinner from '../ui/Spinner/Spinner';
import styles from './Definition.module.scss';
import { DefinitionFooter } from './DefinitionFooter/DefinitionFooter';
import ImageSection from './ImageSection/ImageSection';
import Senses from './Senses/Senses';
interface DefinitionProps {
    word: Word;
    moveNext: () => void;
}

const Definition = ({ word, moveNext }: DefinitionProps) => {
    const { wordData } = useWordData(word);
    const [currentSense, setCurrentSense] = useState<Sense>({} as Sense);

    useEffect(() => {
        setCurrentSense(wordData?.Senses[0] as Sense);
    }, [wordData]);

    if (!word || !wordData) return <Spinner />
    return (
        <article className={styles.definition}>
            <ImageSection word={word} />
            <Senses word={word} wordData={wordData} setCurrentSense={setCurrentSense} />
            <ProTips word={word} sense={currentSense} />
            <DefinitionFooter word={word} moveNext={moveNext} />
        </article>
    );
};

export default Definition;