import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WordList } from 'src/model/WordList';
import { IconLocation } from '../../../../utils/URLUtils';
import Menu from './Menu/Menu';
import styles from './WordListPageHeader.module.scss';

export const WordListPageHeader = ({ wordList }: { wordList: WordList }) => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    return (
        <section className={styles.wordListPageHeader}>
            <img className={styles.backIcon} onClick={() => navigate(-1)}
                src={IconLocation('chevron-left')} alt='back' />
            <h1>{wordList.Title}</h1>
            <span ref={menuRef} onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={styles.menu}>•••</span>
            <Menu
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                trigger={menuRef.current}
            />
        </section>
    );
};
