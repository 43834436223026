import { FC } from 'react';
import { HighlightWord } from '../../../../../utils/TextUtils';
import styles from './DefinitionSection.module.scss';

interface DefinitionSectionProps {
    definition?: string | null;
    example?: string | null;
    translateDefinition?: string | null;
    translateExample?: string | null;
    wordText: string;
}

const DefinitionSection: FC<DefinitionSectionProps> = ({
    definition,
    example,
    translateDefinition,
    translateExample,
    wordText
}) => {
    return (
        <>
            <div className={styles.definition}>
                {definition}
            </div>

            {translateDefinition && <div className={styles.translate}>
                {translateDefinition}
            </div>}

            <div className={styles.example}>
                {HighlightWord(example, wordText)}
            </div>

            {translateExample && <div className={styles.translate}>
                {translateExample}
            </div>}
        </>
    );
};

export default DefinitionSection;