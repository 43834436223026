import { useCallback, useEffect, useRef, useState } from 'react';
import useFetch from 'src/hook/useFetch';
import { useWords } from 'src/hook/useWords';
import { useLearning } from '../../../hook/useLearning';
import useUser from '../../../hook/useUser';
import { Voice } from '../../../model/enum/enums';
import { Word } from '../../../model/Word';
import { WordData } from '../../../model/WordData';
import { GetWordDataFileUrl } from '../../../utils/URLUtils';
import ImageSection from '../../ui/GlobalComponents/ImageSection/ImageSection';
import Spinner from "../../ui/Spinner/Spinner";
import ActionButtons from './ActionButtons/ActionButtons';
import AudioPlayButton from './AudioPlayButton/AudioPlayButton';
import styles from './MainLearn.module.scss';

const MainLearn = () => {
    const { userInfo } = useUser();
    const { learningInfo } = useLearning();
    const [word, setWord] = useState<Word | undefined>(undefined);
    const [index, setIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [wordData, setWordData] = useState<WordData>({} as WordData);
    const { getWordById } = useWords();
    const { send } = useFetch<WordData>();

    const fetchData = useCallback(async () => {

        const wordId = learningInfo?.UnknownWordIds[index];
        if (!wordId || wordId === word?.ID) return;
        const { response } = await send(GetWordDataFileUrl(wordId));
        if (!response) return;

        setWord(getWordById(wordId));
        setWordData(response);

    }, [getWordById, learningInfo?.UnknownWordIds, index, send, word?.ID]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if (!word) return <Spinner />

    return (
        <article className={styles.mainLearn}>
            <div className={styles.mainContent}>
                <ImageSection word={word} />
                <AudioPlayButton
                    wordId={word.ID}
                    onPlayStart={() => setIsPlaying(true)}
                    onPlayEnd={() => setIsPlaying(false)}
                />
            </div>
            <div className={`${styles.wordInfo} ${isPlaying ? styles.playing : ''}`}>
                <h1>{word.Text}</h1>
                <p>/{userInfo?.Voice === Voice.gb ? word.BritishPhonetic : word.AmericanPhonetic}/</p>
            </div>
            <ActionButtons
                word={word}
                wordData={wordData}
                index={index}
                setIndex={setIndex}
            />
        </article>
    );
};

export default MainLearn;