import { NavLink } from 'react-router-dom';
import { IconLocation } from '../../../utils/URLUtils';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <section className={styles.footer}>
      <NavLink to='/wordlists' className={styles.menuItem}>
        {({ isActive }) => (
          <>
            <img src={IconLocation(`brain${isActive ? '-focus' : ''}`)} alt="Discover" />
            <span>Discover</span>
          </>
        )}
      </NavLink>
      <NavLink to='/learn' className={styles.menuItem}>
        {({ isActive }) => (
          <>
            <img src={IconLocation(`book${isActive ? '-focus' : ''}`)} alt="Learn" />
            <span>Learn</span>
          </>
        )}
      </NavLink>
      <NavLink to='/practice' className={styles.menuItem}>
        {({ isActive }) => (
          <>
            <img src={IconLocation(`copy${isActive ? '-focus' : ''}`)} alt="Practice" />
            <span>Practice</span>
          </>
        )}
      </NavLink>
      <NavLink to='/lessons' className={styles.menuItem}>
        {({ isActive }) => (
          <>
            <img src={IconLocation(`friends${isActive ? '-focus' : ''}`)} alt="Lessons" />
            <span>Lessons</span>
          </>
        )}
      </NavLink>
      <NavLink to='/progress' className={styles.menuItem}>
        {({ isActive }) => (
          <>
            <img src={IconLocation(`user${isActive ? '-focus' : ''}`)} alt="Progress" />
            <span>Progress</span>
          </>
        )}
      </NavLink>
    </section>
  );
}

export default Footer;
