import { FC, memo, PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useUser from '../../hook/useUser';
import LearnWizard from '../Learn/LearnWizard/LearnWizard';
import ComponentModal from '../ui/ComponentModal/ComponentModal';
import IFrameModal from '../ui/IFrameModal/IFrameModal';
import ToastContainer from '../ui/Toast/ToastContainer';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import styles from './Layout.module.scss';
import Main from './Main/Main';

// Define pages that don't need layout
const PagesWithoutLayout = ['/wordlists/discover', '/learn/learn-wizard', '/wordlists/wordlist'];

const Layout: FC<PropsWithChildren> = ({ children }) => {
    const { isAuthenticated } = useUser();
    const location = useLocation();
    const [shouldShowLayout, setShouldShowLayout] = useState(false);

    useEffect(() => {
        const hasLayout = !PagesWithoutLayout.includes(location.pathname) && isAuthenticated();
        setShouldShowLayout(hasLayout);
    }, [location.pathname, isAuthenticated]);

    return (
        <>
            {shouldShowLayout ? (
                <article className={styles.layout} >
                    <Header />
                    <Main>
                        {children}
                    </Main>
                    <Footer />
                </article >
            ) : (
                children
            )}
            <IFrameModal />
            <ComponentModal />
            <ToastContainer />
            <LearnWizard />
        </>
    );
}

export default memo(Layout);