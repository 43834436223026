import { FC } from 'react';
import { Word } from '../../../../model/Word';
import { WordData } from '../../../../model/WordData';
import { HighlightWord } from '../../../../utils/TextUtils';
import styles from './ThirdWizardTextContent.module.scss';

interface ThirdWizardTextContentProps {
    word: Word;
    wordData: WordData;
}

const ThirdWizardTextContent: FC<ThirdWizardTextContentProps> = ({ word, wordData }) => {
    const primarySense = wordData?.Senses?.find(sense => sense.id === word.PrimarySenseId);


    return (
        <div className={styles.textContentWrapper}>
            <div className={styles.textContent} >
                <div className={styles.textContentContainer}>
                    <h1 className={styles.clareTitle}>Often appears as</h1>
                    <div className={styles.clareItems}>
                        {primarySense?.cl.map((cl, index) =>
                            <div key={index} className={styles.clareItem}>
                                <div className={styles.rectangle}></div>
                                <div className={styles.clareDescription}>
                                    {HighlightWord(cl, word.Text)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThirdWizardTextContent;