import { Word } from '../model/Word';

export const parseWordLine = (line: string): Word => {
    const [id, rank, text, otherForms, primaryMeaning, primarySenseId, type, britishPhonetic, americanPhonetic] = line.split('|');

    return {
        ID: parseInt(id),
        Rank: parseInt(rank),
        Text: text,
        OtherForms: otherForms,
        PrimaryMeaning: primaryMeaning,
        PrimarySenseId: primarySenseId,
        Type: parseInt(type),
        BritishPhonetic: britishPhonetic,
        AmericanPhonetic: americanPhonetic,
        AllForms: otherForms ? otherForms.split(',') : []
    };
};

export const parseWordsFile = (content: string): Word[] => {
    return content
        .split('\n')
        .filter(line => line.trim())
        .map(parseWordLine);
};

