import Slide from "react-slick";
import { Word } from '../../../model/Word';
import { Sense, WordData } from '../../../model/WordData';
import { SliderSettings } from '../../../utils/SliderSetting';
import styles from "./Senses.module.scss";
import SenseItem from "./SenseItem/SenseItem";

interface SensesProps {
    word: Word;
    wordData: WordData;
    setCurrentSense: (sense: Sense) => void;
}

const Senses = ({ word, wordData, setCurrentSense }: SensesProps) => {

    // const fetchData = useCallback(async () => {

    // }, [word, wordData]);

    // useEffect(() => {
    //     fetchData();
    // }, [fetchData]);


    if (wordData?.Senses?.length === 0) return null;

    return (
        <section className={styles.senses}>
            <Slide {...SliderSettings()} afterChange={(index) => setCurrentSense(wordData?.Senses[index] as Sense)}>
                {wordData?.Senses?.map((sense, index) => (
                    <SenseItem key={index} word={word} sense={sense} />
                ))}
            </Slide>
        </section>
    );
}

export default Senses;