import { Navigate, Route, Routes } from 'react-router-dom';
import Discover from '../Discover/Discover';
import { DiscoverPage } from '../Discover/DiscoverPage/DiscoverPage';
import { WordListPage } from '../Discover/WordListPage/WordListPage';
import Learn from '../Learn/Learn';
import Lessons from '../Lessons/Lessons';
import Practice from '../Practice/Practice';
import Progress from '../Progress/Progress';

const HomePage = () => {
    return (
        <Routes>
            <Route index element={<Navigate to="/wordlists" replace />} />
            <Route path='practice' element={<Practice />} />
            <Route path='lessons' element={<Lessons />} />
            <Route path='wordlists' element={<Discover />} />
            <Route path='learn' element={<Learn />} />
            <Route path='progress' element={<Progress />} />
            <Route path='wordlists/discover' element={<DiscoverPage />} />
            <Route path='wordlists/wordlist' element={<WordListPage />} />
            <Route path='*' element={<Discover />} />
        </Routes>
    );
}

export { HomePage };

