
import useUser from 'src/hook/useUser';
import { Voice } from 'src/model/enum/enums';
import { Word } from 'src/model/Word';
import VolumeButton from '../VolumeButton/VolumeButton';
import styles from './VolumeSection.module.scss';

const VolumeSection = ({ word }: { word: Word }) => {
    const { userInfo } = useUser();
    return (
        <div className={styles.volumeSection}>
            <VolumeButton word={word} />
            <span>/{userInfo?.Voice === Voice.gb ? word.BritishPhonetic : word.AmericanPhonetic}/</span>
            <span>#{word.Rank}</span>
        </div>
    );
};

export default VolumeSection;