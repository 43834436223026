import { FC } from 'react';
import { MenuPosition } from '../../../../../model/enum/enums';
import { IconLocation } from '../../../../../utils/URLUtils';
import DropdownMenu from '../../../../ui/DropDownMenu/DropdownMenu';
import styles from './Menu.module.scss';

interface MenuProps {
    isOpen: boolean;
    onClose: () => void;
    trigger: HTMLSpanElement | null;
}

const Menu: FC<MenuProps> = ({ isOpen, onClose, trigger }) => {

    return (
        <DropdownMenu
            isOpen={isOpen}
            onClose={onClose}
            trigger={trigger}
            position={MenuPosition.BottomLeft}
        >
            <div className={styles.menuItems}>
                <div className={styles.menuItem} >
                    <img src={IconLocation('plus2')} alt="CardsView" />
                    <span>Add Words</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('download')} alt="TagsView" />
                    <span>Import Words</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('x')} alt="ListView" />
                    <span>Remove Words</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('clipboard')} alt="ListView" />
                    <span>Edit List</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('share2')} alt="ListView" />
                    <span>Share List</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('sort')} alt="ListView" />
                    <span>Sort</span>
                </div>
            </div>
        </DropdownMenu>
    );
};

export default Menu;