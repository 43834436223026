import { FC } from 'react';
import { useLearning } from '../../../../../hook/useLearning';
import { useLearnWizard } from '../../../../../hook/useLearnWizard';
import { LearningArray } from '../../../../../model/enum/enums';
import { Word } from '../../../../../model/Word';
import { WordData } from '../../../../../model/WordData';
import styles from './ButtonSection.module.scss';

interface ButtonSectionProps {
    word: Word;
    wordData: WordData;
}

const ButtonSection: FC<ButtonSectionProps> = ({ word, wordData }) => {
    const { close } = useLearnWizard();
    const { removeWord } = useLearning();


    const handleLearnMore = () => {

    };

    const handleNextWord = () => {
        removeWord(LearningArray.UnknownWordIds, word.ID);
        close();
    };


    return (
        <>
            <button className={styles.learnMoreButton} onClick={() => handleLearnMore()}>Learn More</button>
            <button className={styles.nextWordButton} onClick={() => handleNextWord()}>Next Word</button>
        </>
    );
};

export default ButtonSection;