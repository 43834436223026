import { WIZARD_COMPONENTS } from '../../../hook/learnWizardRegistry';
import { useLearnWizard } from '../../../hook/useLearnWizard';
import { AnimationType, LearnWizardStep } from '../../../model/enum/enums';
import FooterSection from './FooterSection/FooterSection';
import HeaderSection from './HeaderSection/HeaderSection';
import styles from './LearnWizard.module.scss';



const LearnWizard = () => {
    const {
        isOpen,
        stepKey,
        stepProps,
        animation,
        close,
        goToNext,
        goToPrevious,
        isFirstStep,
        isLastStep
    } = useLearnWizard();

    if (!isOpen || !stepKey) return null;

    const WizardStep = WIZARD_COMPONENTS[stepKey];

    if (!WizardStep) {
        console.error(`No wizard step found for key: ${stepKey}`);
        return null;
    }

    return (
        <div className={`${styles.learnWizard} ${stepKey === LearnWizardStep.First ? styles.slideRight : ''}`}>
            <HeaderSection word={stepProps?.word} onClose={close} stepKey={stepKey} />

            <div
                key={`${stepKey}-${animation}`}
                className={`${styles.wizardContent} ${styles[animation || AnimationType.SlideRight]}`}
            >
                <WizardStep {...stepProps} />
            </div>

            <FooterSection
                onPrevious={animation => goToPrevious(animation)}
                onNext={animation => goToNext(animation)}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                stepKey={stepKey}
            />
        </div>
    );
};

export default LearnWizard;