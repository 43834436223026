import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Slide from 'react-slick';
import { Word } from '../../../../model/Word';
import { SliderSettings } from '../../../../utils/SliderSetting';
import { SlideItem } from './SlideItem/SlideItem';
import styles from './Slider.module.scss';

interface SliderProps {
    words: Word[];
    setCurrentWord: (word: Word) => void;
    goToNextRange: () => void;
}

export const Slider: FC<SliderProps> = ({ words, setCurrentWord, goToNextRange }) => {
    const sliderRef = useRef<Slide>(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const moveNext = useCallback(() => {
        if (currentIndex < words.length - 1) {
            sliderRef.current?.slickNext();
        }
        else {
            goToNextRange();
        }
    }, [currentIndex, words.length, goToNextRange]);

    const handleChange = useCallback(
        (oldIndex: number, newIndex: number) => {
            setCurrentIndex(newIndex);
            setCurrentWord(words[newIndex]);
        },
        [setCurrentWord, words]
    );

    useEffect(() => {
        if (words.length > 0) {
            setCurrentIndex(0);
            setCurrentWord(words[0]);
            sliderRef.current?.slickGoTo(0);
        }
    }, [words, setCurrentWord]);


    return (
        <section className={styles.slider}>
            <Slide {...SliderSettings()} ref={sliderRef} beforeChange={handleChange}>
                {words.map((word, index) => (
                    <SlideItem key={index} word={word} moveNext={moveNext} />
                ))}
            </Slide>
        </section>
    );
};
