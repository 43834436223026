import { FC, useCallback, useRef, useState } from 'react';
import useUser from '../../../../hook/useUser';
import { GetSpeechUrl, IconLocation } from '../../../../utils/URLUtils';
import styles from './AudioPlayer.module.scss';

interface AudioPlayerProps {
    text: string;
}

const AudioPlayer: FC<AudioPlayerProps> = ({ text }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [audioStatus, setAudioStatus] = useState(false);
    const { userInfo } = useUser();

    const startAudio = useCallback(() => {
        const currentAccent = userInfo?.Voice;
        let link = GetSpeechUrl(currentAccent, text);
        setAudioStatus(true);
        fetch(link)
            .then((response) => response.text())
            .then((response) => {
                if (audioRef.current) {
                    audioRef.current.src = response;
                    audioRef.current.play();
                }
            })
            .catch((e) => {
                console.log('e', e);
            });
    }, [text, userInfo]);

    const pauseAudio = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
        setAudioStatus(false);
    }, []);

    return (
        <>
            <audio
                onEnded={() => setAudioStatus(false)}
                ref={audioRef}
            />
            {audioStatus ? (
                <img onClick={() => pauseAudio()} className={styles.pauseIcon}
                    src={IconLocation('pause-circle')} alt="Pause" />
            ) : (
                <img onClick={() => startAudio()} className={styles.playIcon}
                    src={IconLocation('play-circle')} alt="Play" />
            )}
        </>
    );
};

export default AudioPlayer;