const BaseRazorUrl = "https://my.wrdp.app";
const BaseCDNLocation = "https://cdn-wordup.com";
const BaseDefinitionUrl = "https://define.wrdp.app";
const BaseSenseImageUrl = "https://word-images.cdn-wordup.com";
const FriendsBaseUrl = "https://friends.wrdp.app";
const BaseSpeechUrl = "https://get-speech.wrdp.app";
const BaseVoiceUrl = "https://speech.cdn-wordup.com";
const BaseSQS = 'https://sqs.eu-west-1.amazonaws.com/077176806650';

const IsDark = () => false;

export const ImageLocation = (fileName: string) => `${BaseCDNLocation}/AppImages/${fileName}`;

export const IconLocation = (fileName: string, forceLight: boolean = false) => `${BaseCDNLocation}/AppImages/Icons/${(IsDark() || forceLight ? 'white' : 'black')}/${fileName}.png`;

export const DirectIconLocation = (fileName: string) => `${BaseCDNLocation}/AppImages/Icons/${fileName}.png `;

export const ScenariosLocation = (fileName: string) => `${BaseCDNLocation}/scenarios/${fileName}.webp `;

export const GetDefinitionResourceUrl = (fileName: string) => `${BaseDefinitionUrl}/Resources/${fileName}`;

export const SenseImageLocation = (senseId: string) => `${BaseSenseImageUrl}/senses/${senseId}.webp`;

export const SenseVideoLocation = (senseId: string) => `${BaseSenseImageUrl}/video/${senseId}.mp4`;

export const GetRazorSiteUrl = (url: string, token?: string, queryString: string = '') => `${BaseRazorUrl}/${url}?t=${token}${queryString ? `&${queryString}` : ''}`;

export const GetWordApiUrl = (wordId: number) => `${BaseDefinitionUrl}/WordById/${wordId}`;

export const GetFriendsUrl = (userId: string) => `${FriendsBaseUrl}/v2/friends/${userId}`;

export const GetNearbyPeopleUrl = (userId: string, country: string) => `${FriendsBaseUrl}/nearby/${userId}/${country}`;

export const GetWordDataUrl = (wordId: number) => `${BaseDefinitionUrl}/ById/${wordId}`;

export const GetTipsImageUrl = (example: string) => `${BaseDefinitionUrl}/TipsImage?example=${example.replaceAll('%', '%25').replaceAll('&', '%26').replaceAll(';', '%3B')}`;

export const GetSpeechUrl = (accent: string = 'gb', text: string) => `${BaseSpeechUrl}/url/q/${accent}/${text}`;

export const GetVoiceUrl = (accent: string = 'gb', sex: string, wordId: string) => `${BaseVoiceUrl}/words/all/${accent}/${sex}/${wordId}`;

export const GetQueueUrl = (queueName: string) => `${BaseSQS}/${queueName}`;

export const GetWellDoneLottieUrl = (fileName: number) => `${BaseCDNLocation}/animation/Review/Welldone/${fileName}.json`;

export const GetStartPageUrl = () => `${BaseRazorUrl}/onboarding/start`;

export const GetTranslateFilesUrl = (userLanguage: string) => `${BaseCDNLocation}/translations/v6/${userLanguage}.json.gz`;

export const GetResourcesUrl = (fileName: string) => `${BaseCDNLocation}/Resources/${fileName}`;

export const GetWellDoneLottieSound = (fileName: number) => `${BaseCDNLocation}/audio/sounds/${fileName}.mp3`;

export const GetWordDataFileUrl = (wordId: number) => `${BaseCDNLocation}/v2024-1-18/${wordId}.gz`;
