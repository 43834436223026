import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnimationType, LearnWizardStep } from '../../model/enum/enums';

interface LearnWizardState {
    isOpen: boolean;
    stepKey: LearnWizardStep;
    stepProps: Record<string, any> | null;
    animation: AnimationType;
}

const initialState: LearnWizardState = {
    isOpen: false,
    stepKey: LearnWizardStep.First,
    stepProps: null,
    animation: AnimationType.SlideRight,
};

const learnWizardSlice = createSlice({
    name: 'learnWizard',
    initialState,
    reducers: {
        openWizard: (state, action: PayloadAction<{
            stepKey: LearnWizardStep;
            props?: Record<string, any>;
            animation?: AnimationType;
        }>) => {
            state.isOpen = true;
            state.stepKey = action.payload.stepKey;
            state.stepProps = action.payload.props || null;
            state.animation = action.payload.animation || AnimationType.SlideRight;
        },
        closeWizard: (state) => {
            state.isOpen = false;
            state.stepKey = LearnWizardStep.First;
            state.stepProps = null;
            state.animation = AnimationType.SlideRight;
        },
        nextStep: (state, action: PayloadAction<{
            nextStep: LearnWizardStep;
            animation: AnimationType;
        }>) => {
            state.stepKey = action.payload.nextStep;
            state.animation = action.payload.animation;
        },
        previousStep: (state, action: PayloadAction<{
            previousStep: LearnWizardStep;
            animation: AnimationType;
        }>) => {
            state.stepKey = action.payload.previousStep;
            state.animation = action.payload.animation;
        }
    }
});

export const {
    openWizard,
    closeWizard,
    nextStep,
    previousStep
} = learnWizardSlice.actions;

export default learnWizardSlice.reducer;