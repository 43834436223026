import { useCallback, useEffect, useState } from 'react';
import { useLearning } from '../../../hook/useLearning';
import useUser from '../../../hook/useUser';
import { useWordListData } from '../../../hook/useWordListData';
import { useWords } from '../../../hook/useWords';
import { Direction } from '../../../model/enum/enums';
import { Word, WordType } from '../../../model/Word';
import { WordList } from '../../../model/WordList';
import { QueryString } from '../../../utils/QueryStringUtils';
import styles from './DiscoverPage.module.scss';
import { DiscoverPageHeader } from './DiscoverPageHeader/DiscoverPageHeader';
import KnowledgeMap from './KnowledgeMap/KnowledgeMap';
import ListView from './ListView/ListView';
import { Slider } from './Slider/Slider';
import TagsView from './TagsView/TagsView';

export const DiscoverPage = () => {
    const { getWordsByRange, getWordsByWordList } = useWords();
    const { userInfo } = useUser();
    const [words, setWords] = useState<Word[]>([]);
    const [filterWords, setFilterWords] = useState<Word[]>([]);
    const { learningInfo } = useLearning();
    const [currentWord, setCurrentWord] = useState<Word>();
    const [currentRange, setCurrentRange] = useState<number>(1);
    const [wordList, setWordList] = useState<WordList>();
    const { wordListData } = useWordListData(wordList, wordList?.Type === 'custom');
    const [selectedView, setSelectedView] = useState<'cards' | 'tags' | 'list'>('cards');


    const filterWordsByRange = useCallback((range: number, direction?: Direction) => {

        if (range < 1 || range > 242) return;


        let fetchedWords;
        if (wordList?.IsAll)
            fetchedWords = getWordsByRange(range);
        else
            fetchedWords = getWordsByWordList(wordListData, range);


        if (fetchedWords.length === 0) return;


        const filteredWords = fetchedWords.filter(word =>
            !learningInfo?.KnownWordIds.includes(word.ID) &&
            !learningInfo?.UnknownWordIds.includes(word.ID)
        );

        if (filteredWords.length === 0) {
            if (!direction) direction = Direction.Next;
            const newRange = direction === Direction.Next ? range + 1 : range - 1;
            filterWordsByRange(newRange, direction);
            return;
        }


        //setCurrentRange(Math.floor(filteredWords[0]?.Rank / 100) + 1);
        setCurrentRange(range);
        setCurrentWord(filteredWords[0]);
        setWords(fetchedWords);
        setFilterWords(filteredWords);

    }, [learningInfo, getWordsByRange, getWordsByWordList, wordList?.IsAll, wordListData]);

    const handleRangeChange = useCallback((newRange: number) => {
        const direction = newRange > currentRange ? Direction.Next : Direction.Prev;
        filterWordsByRange(newRange, direction);
    }, [filterWordsByRange, currentRange]);

    const goToNextRange = useCallback(() => {
        filterWordsByRange(currentRange + 1, Direction.Next);
    }, [filterWordsByRange, currentRange]);


    useEffect(() => {
        if (words?.length > 0) return;
        const wordListId = QueryString('id');
        if (!wordListId) return;
        const wordList = learningInfo?.WordLists.find(x => x.ID === wordListId);
        if (!wordList) return;

        setWordList(wordList);

        filterWordsByRange(wordList.IsAll ? userInfo?.SelectedRange ?? 1 : 1);

    }, [filterWordsByRange, userInfo?.SelectedRange, words?.length, learningInfo?.WordLists]);


    const renderSelectedView = () => {
        switch (selectedView) {
            case 'cards':
                return <Slider words={filterWords} setCurrentWord={setCurrentWord} goToNextRange={goToNextRange} />;
            case 'tags':
                return <TagsView words={words} setCurrentWord={setCurrentWord} />;
            case 'list':
            default:
                return <ListView words={words} setCurrentWord={setCurrentWord} />;
        }
    };

    return (
        <article className={styles.discoverPage}>
            <DiscoverPageHeader onViewChange={setSelectedView} />
            {renderSelectedView()}
            <KnowledgeMap currentWord={currentWord}
                currentRangeNumber={currentRange} words={words}
                wordType={wordList?.Title === 'Idioms' ? WordType.Phrase : WordType.Word}
                onRangeChange={handleRangeChange} />
        </article>
    );
};