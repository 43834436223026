import { FC, useCallback, useState } from 'react';
import { Word } from '../../../../model/Word';
import { GetVoiceUrl, IconLocation } from '../../../../utils/URLUtils';
import useUser from '../../../../hook/useUser';
import styles from './VolumeButton.module.scss';

interface VolumeButtonProps {
    word: Word;
}

const VolumeButton: FC<VolumeButtonProps> = ({ word }) => {
    const { userInfo } = useUser();
    const [male, setMale] = useState(true);

    const handleVolume = useCallback(() => {
        const accent = userInfo?.Voice;
        const link = GetVoiceUrl(accent, male ? 'm' : 'f', word.ID + '.mp3');
        const audio = new Audio(link);
        audio.play();
        setMale((prevState) => !prevState);
    }, [word, male, setMale, userInfo]);

    return (
        <img
            src={IconLocation('volume-1')}
            alt='volume'
            onClick={handleVolume}
            className={styles.volumeButton}
        />
    );
};

export default VolumeButton;