import { FC } from 'react';
import { MenuPosition } from '../../../../../model/enum/enums';
import { IconLocation } from '../../../../../utils/URLUtils';
import DropdownMenu from '../../../../ui/DropDownMenu/DropdownMenu';
import styles from './ViewMenu.module.scss';

interface ViewMenuProps {
    isOpen: boolean;
    onClose: () => void;
    trigger: HTMLSpanElement | null;
    onViewChange: (view: 'cards' | 'tags' | 'list') => void;
}

const ViewMenu: FC<ViewMenuProps> = ({ isOpen, onClose, trigger, onViewChange }) => {

    return (
        <DropdownMenu
            isOpen={isOpen}
            onClose={onClose}
            trigger={trigger}
            position={MenuPosition.BottomLeft}
        >
            <div className={styles.menuItems}>
                <div className={styles.menuItem} onClick={() => { onViewChange('cards'); onClose(); }} >
                    <img src={IconLocation('cards_view')} alt="CardsView" />
                    <span>Cards view</span>
                </div>
                <div className={styles.menuItem} onClick={() => { onViewChange('tags'); onClose(); }}>
                    <img src={IconLocation('tag_view')} alt="TagsView" />
                    <span>Tags view</span>
                </div>
                <div className={styles.menuItem} onClick={() => { onViewChange('list'); onClose(); }}>
                    <img src={IconLocation('list_view')} alt="ListView" />
                    <span>List view</span>
                </div>
            </div>
        </DropdownMenu>
    );
};

export default ViewMenu;