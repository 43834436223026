import { FC, useRef, useState } from 'react';
import { Word } from '../../../../model/Word';
import styles from './ImageSection.module.scss';
import MoreOptionsMenu from './MoreOptionsMenu/MoreOptionsMenu';
import VideoSection from './VideoSection/VideoSection';

interface ImageSectionProps {
    word: Word;
    hasRank?: boolean;
}

const ImageSection: FC<ImageSectionProps> = ({
    word,
    hasRank = true
}) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const moreOptionsRef = useRef<HTMLSpanElement>(null);
    return (
        <section className={styles.imageSection}>
            <VideoSection word={word} />
            {hasRank && <div className={styles.wordRank}>#{word.Rank}</div>}
            <span ref={moreOptionsRef} onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={styles.moreOptions}>•••</span>
            <MoreOptionsMenu
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                trigger={moreOptionsRef.current}
                word={word}
            />
        </section>
    );
};

export default ImageSection;