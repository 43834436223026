import { useRef, useState } from 'react';
import { useComponentModal } from 'src/hook/useComponentModal';
import { IconLocation } from 'src/utils/URLUtils';
import { Word } from '../../../model/Word';
import styles from './ImageSection.module.scss';
import MoreOptionsMenu from './MoreOptionsMenu/MoreOptionsMenu';
import VideoSection from './VideoSection/VideoSection';

interface ImageSectionProps {
    word: Word;
    hasRank?: boolean;
}

const ImageSection = ({ word }: ImageSectionProps) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const moreOptionsRef = useRef<HTMLSpanElement>(null);
    const { close } = useComponentModal();

    return (
        <section className={styles.imageSection}>
            <VideoSection word={word} />
            <span ref={moreOptionsRef} onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={styles.moreOptions}>•••</span>
            <span onClick={() => close()}
                className={styles.close}>
                <img src={IconLocation('x')} alt="close" />
            </span>
            <MoreOptionsMenu
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                trigger={moreOptionsRef.current}
                word={word}
            />
        </section>
    );
};

export default ImageSection;