import { FC, useCallback, useEffect, useState } from "react";
import useFetch from "../../../hook/useFetch";
import useUser from "../../../hook/useUser";
import { useWordTranslate } from "../../../hook/useWordTranslate";
import { Word } from "../../../model/Word";
import { WordData } from "../../../model/WordData";
import { GetWellDoneLottieSound, GetWellDoneLottieUrl } from "../../../utils/URLUtils";
import Spinner from "../../ui/Spinner/Spinner";
import MainSection from "./MainSection/MainSection";

interface FinalWizardProps {
    word: Word;
    wordData: WordData;
}

const FinalWizard: FC<FinalWizardProps> = ({ word, wordData }) => {
    const { isLoading, send } = useFetch<any>();
    const [translateWord, setTranslateWord] = useState<string>('');
    const [animationData, setAnimationData] = useState(null);
    const { isNonEnglishNativeLanguage } = useUser();
    const { getTranslateBySenseId } = useWordTranslate();

    const fetchData = useCallback(async () => {
        const randomNumber = Math.floor(Math.random() * 15) + 1;
        const randomNumber1 = Math.floor(Math.random() * 11) + 1;
        if (isNonEnglishNativeLanguage()) {
            setTranslateWord(getTranslateBySenseId(word?.PrimarySenseId));
        }
        const { response } = await send(GetWellDoneLottieUrl(randomNumber));
        if (response) setAnimationData(response);
        
        const audio = new Audio(GetWellDoneLottieSound(randomNumber1));
        audio.play().catch(error => {
            console.error('Error playing audio:', error);
        });

    }, [send, isNonEnglishNativeLanguage, getTranslateBySenseId, word.PrimarySenseId]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if (isLoading) return <Spinner />

    return (
        <MainSection word={word} wordData={wordData}
            translateWord={translateWord} animationData={animationData} />
    );
};

export default FinalWizard;
