import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { LearningArray } from '../model/enum/enums';
import { Learning } from '../model/User';
import { learningActions } from '../store/slices/learningSlice';
import { appDispatch, RootState } from '../store/store';

export const useLearning = () => {
    const ac = useMemo(
        () => bindActionCreators(learningActions, appDispatch),
        []
    );

    const { learningInfo } = useSelector((state: RootState) => state.learning);

    useEffect(() => {
        if (!learningInfo) {
            ac.getLearning();
        }
    }, [ac, learningInfo]);

    const setLearningInfo = useCallback((info: Learning | null) => {
        ac.setLearning(info);
    }, [ac]);

    const updateLearningInfo = useCallback((info: Partial<Learning>) => {
        ac.updateLearning(info);
    }, [ac]);

    const addWord = useCallback((arrayName: LearningArray, wordId: number) => {
        if (learningInfo) {
            const updatedArray = Array.from(new Set([...learningInfo[arrayName], wordId])); // Add and ensure uniqueness
            updateLearningInfo({ [arrayName]: updatedArray });
        }
    }, [learningInfo, updateLearningInfo]);

    const removeWord = useCallback((arrayName: LearningArray, wordId: number) => {
        if (learningInfo) {
            const updatedArray = learningInfo[arrayName].filter(id => id !== wordId);
            updateLearningInfo({ [arrayName]: updatedArray });
        }
    }, [learningInfo, updateLearningInfo]);



    return useMemo(() => ({
        learningInfo,
        setLearningInfo,
        updateLearningInfo,
        addWord, // Expose the add function
        removeWord, // Expose the remove function
    }), [
        learningInfo,
        setLearningInfo,
        updateLearningInfo,
        addWord,
        removeWord,
    ]);
};