import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../model/User";
import { userStorage } from "../../utils/StorageUtils";
import { GetRazorSiteUrl } from "../../utils/URLUtils";
import { LoadingStatus } from "../../model/enum/enums";

interface UserState {
    userInfo: User | null;
    isUserLoaded: boolean;
    status: LoadingStatus;
    error: string | null;
}

const initialState: UserState = {
    userInfo: null,
    isUserLoaded: false,
    status: LoadingStatus.IDLE,
    error: null
};

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (token: string) => {
        const response = await fetch(GetRazorSiteUrl('settings', token));
        if (!response.ok) {
            throw new Error('Failed to fetch user settings');
        }

        const userData = await response.json() as Omit<User, 'Token'>;
        return { ...userData, Token: token };
    }
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUser: (state) => {
            state.userInfo = userStorage.get();
            state.isUserLoaded = true;
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            userStorage.set(action.payload);
            state.userInfo = action.payload;
            state.isUserLoaded = true;
        },
        updateUser: (state, action: PayloadAction<Partial<User>>) => {
            state.userInfo = userStorage.update(action.payload);
        },
        logout: (state) => {
            Object.assign(state, initialState);
            userStorage.set(null);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.status = LoadingStatus.LOADING;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.status = LoadingStatus.SUCCEEDED;
                state.userInfo = action.payload;
                state.isUserLoaded = true;
                state.error = null;
                userStorage.set(action.payload);
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = LoadingStatus.FAILED;
                state.error = action.error.message ?? 'Failed to fetch user';
            });
    },
});

export const userActions = { ...userSlice.actions, fetchUser };
export default userSlice.reducer;