import { useComponentModal } from "src/hook/useComponentModal";
import { useLearning } from "src/hook/useLearning";
import { AnimationType } from "src/model/enum/enums";
import { Word } from "../../../../model/Word";
import styles from "./TagsView.module.scss";

interface TagsViewProps {
    words: Word[];
    setCurrentWord: (word: Word) => void;
}

const TagsView = ({ words, setCurrentWord }: TagsViewProps) => {
    const { learningInfo } = useLearning();

    const { open } = useComponentModal();

    const getSquareClassName = (wordId: number): string => {
        let className = styles.square;

        if (learningInfo?.KnownWordIds.includes(wordId)) {
            className += ` ${styles.known}`;
        }
        else if (learningInfo?.UnknownWordIds.includes(wordId)) {
            className += ` ${styles.shouldLearn}`;
        }

        return className;
    };

    const handleClickWord = (word: Word) => {
        setCurrentWord(word);
        open('Definition', { word: word }, AnimationType.SlideUp);
    };

    return (
        <section className={styles.tagsView}>
            {words.map((word) => (
                <div
                    key={word.ID}
                    className={getSquareClassName(word.ID)}
                    onClick={() => handleClickWord(word)}
                >
                    {word.Text}
                </div>
            ))}
        </section>
    );
};

export default TagsView;