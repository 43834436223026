import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { LoadingStatus } from "../model/enum/enums";
import { SavedProgress, User } from '../model/User';
import { userActions } from '../store/slices/userSlice';
import { AppDispatch, RootState } from '../store/store';
import { insertIntoQueue } from "../utils/QueueUtils";
import { GetQueueUrl } from "../utils/URLUtils";

const useUser = () => {
    const dispatch = useDispatch<AppDispatch>();
    
    // Memoize bound actions to prevent recreating on every render
    const ac = useMemo(
        () => bindActionCreators(userActions, dispatch),
        [dispatch]
    );

    const { userInfo, isUserLoaded, status, error } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (!isUserLoaded) {
            ac.getUser();
        }
    }, [ac, isUserLoaded]);

    const setUserInfo = useCallback((info: User | null) => {
        ac.setUser(info);
    }, [ac]);

    const updateUserInfo = useCallback((info: Partial<User>) => {
        ac.updateUser(info);
    }, [ac]);

    const handleLogout = useCallback(() => {
        ac.logout();
    }, [ac]);

    const isAuthenticated = useCallback(() => {
        return !!userInfo?.Token;
    }, [userInfo?.Token]);

    const isNonEnglishNativeLanguage = useCallback(() => {
        return userInfo?.NativeLanguageCode && userInfo?.NativeLanguageCode !== 'en';
    }, [userInfo?.NativeLanguageCode]);

    const saveProgress = useCallback((action: string, wordId: number) => {
        const newProgress: SavedProgress = { Action: action, WordId: wordId };
        const updatedProgress = [...userInfo?.SavedProgress || [], newProgress];

        // Check if we have 5 items
        if (updatedProgress.length >= 5) {
            // Prepare actions string
            const actionsString = updatedProgress.map(progress => `${progress.Action}:${progress.WordId}`).join('|');

            // Prepare jsonData
            const jsonData = JSON.stringify({
                Token: userInfo?.Token,
                Actions: actionsString
            });

            // Send to the queue
            insertIntoQueue(GetQueueUrl('SaveProgress'), jsonData);

            // Clear the saved progress
            ac.updateUser({ SavedProgress: [] });
        } else {
            // Update the user state with the new progress
            ac.updateUser({ SavedProgress: updatedProgress });
        }
    }, [ac, userInfo?.SavedProgress, userInfo?.Token]);

    const fetchUserData = useCallback(async (token?: string) => {
        const effectiveToken = token || userInfo?.Token;
        
        if (!effectiveToken) {
            console.error('Token is required to fetch user settings');
            return;
        }

        try {
            await ac.fetchUser(effectiveToken);
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    }, [ac, userInfo?.Token]);

    return useMemo(() => ({
        userInfo,
        setUserInfo,
        updateUserInfo,
        handleLogout,
        isAuthenticated,
        isUserLoaded,
        isNonEnglishNativeLanguage,
        saveProgress,
        fetchUserData,
        isLoading: status === LoadingStatus.LOADING,
        isError: status === LoadingStatus.FAILED,
        error
    }), [
        userInfo,
        setUserInfo,
        updateUserInfo,
        handleLogout,
        isAuthenticated,
        isUserLoaded,
        isNonEnglishNativeLanguage,
        saveProgress,
        fetchUserData,
        status,
        error
    ]);
};

export default useUser;