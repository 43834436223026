import { FC } from 'react';
import { useToast } from '../../../../hook/useToasts';
import { ContentType, MenuPosition } from '../../../../model/enum/enums';
import { Word } from '../../../../model/Word';
import { insertIntoQueue } from '../../../../utils/QueueUtils';
import { GetQueueUrl, IconLocation } from '../../../../utils/URLUtils';
import DropdownMenu from '../../../ui/DropDownMenu/DropdownMenu';
import styles from './MoreOptionsMenu.module.scss';

interface MoreOptionsMenuProps {
    isOpen: boolean;
    onClose: () => void;
    trigger: HTMLSpanElement | null;
    word: Word;
}

const MoreOptionsMenu: FC<MoreOptionsMenuProps> = ({ isOpen, onClose, trigger, word }) => {
    const toast = useToast();

    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Share Word',
                    text: word.Text
                });
            } else {
                await navigator.clipboard.writeText(word.Text);
                toast.showSuccess('Word copied to clipboard!');
            }
        } catch (error) {
            console.error('Error sharing:', error);
        }
        onClose();
    };


    const handleReport = async () => {
        let jsonData = JSON.stringify({
            WordId: word.ID,
            Word: word.Text,
            Type: ContentType.Image,
            ContentId: word.PrimarySenseId,
            Reason: ""
        });

        insertIntoQueue(GetQueueUrl('bad-video'), jsonData);
        toast.showSuccess('Thank you for caring.');
        onClose();
    };


    return (
        <DropdownMenu
            isOpen={isOpen}
            onClose={onClose}
            trigger={trigger}
            position={MenuPosition.BottomLeft}
        >
            <div className={styles.menuItems}>
                <div className={styles.menuItem} onClick={handleShare}>
                    <img src={IconLocation('share2')} alt="Share" />
                    <span>Share</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('clipboard')} alt="Share" />
                    <span>Add Note</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('Star')} alt="Share" />
                    <span>Favorite</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('Bookmark')} alt="Share" />
                    <span>Add to list</span>
                </div>
                <div className={styles.menuItem} onClick={handleReport}>
                    <img src={IconLocation('flag_contextmenu')} alt="Report" />
                    <span>Report Image</span>
                </div>
            </div>
        </DropdownMenu>
    );
};

export default MoreOptionsMenu;