import { useCallback, useEffect, useState } from 'react';
import useFetch from 'src/hook/useFetch';
import { Lesson } from '../../model/Lesson';
import { GetResourcesUrl, ScenariosLocation } from '../../utils/URLUtils';
import styles from './Lessons.module.scss';
import { LessonsItem } from './LessonsItem/LessonsItem';

const Lessons = () => {
    const [categories, setCategories] = useState([]);
    const { send } = useFetch<any>();

    const fetchLessons = useCallback(async () => {
        try {
            const { response } = await send(GetResourcesUrl('Scenarios.json'));
            const { categories } = response;
            setCategories(categories);
        } catch (error) {
            console.error('Error fetching lessons:', error);
        }
    }, [send]);
    
    useEffect(() => {
        fetchLessons();
    }, [fetchLessons]);

    return (
        <article className={styles.lessons}>
            {categories.map((category: Lesson) => (
                <div key={category.cat} className={styles.section}>
                    <h1 className={styles.title}>{category.cat}</h1>
                    <div className={styles.itemsContainer}>
                        {category.ctx.map((ctx, ctxIndex) => (
                            <LessonsItem
                                key={ctxIndex}
                                ctx={ctx}
                                imageSrc={ScenariosLocation(`${category.ref}${ctx.ref}`)}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </article>
    );
}

export default Lessons;