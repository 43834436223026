import { useState } from 'react';
import { useLearnWizard } from '../../../../hook/useLearnWizard';
import { LearnWizardStep } from '../../../../model/enum/enums';
import { Word } from '../../../../model/Word';
import { WordData } from '../../../../model/WordData';
import SkipModal from '../SkipModal/SkipModal';
import styles from './ActionButtons.module.scss';

interface ActionButtonsProps {
    word: Word;
    wordData: WordData;
    index: number;
    setIndex: (index: number) => void;
}

const ActionButtons = ({ word, wordData, index, setIndex }: ActionButtonsProps) => {
    const { open } = useLearnWizard();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLearn = () => {
        open(LearnWizardStep.First, { word, wordData });
    };

    const handleSkip = () => {
        setIsModalOpen(true);
    };

    return (
        <div className={styles.actionButtons}>
            <button className={styles.learnButton} onClick={handleLearn}>
                Learn
            </button>
            <button className={styles.skipButton} onClick={handleSkip}>
                Skip
            </button>
            <SkipModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                word={word}
                handleLearn={handleLearn}   
            />
        </div>
    );
};

export default ActionButtons;