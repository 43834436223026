import { FC, useCallback, useEffect, useState } from 'react';
import { useWordTranslate } from 'src/hook/useWordTranslate';
import useFetch from '../../../../hook/useFetch';
import useUser from '../../../../hook/useUser';
import { ResponseType } from '../../../../model/enum/enums';
import { Word } from '../../../../model/Word';
import { WordData } from '../../../../model/WordData';
import { GetRazorSiteUrl } from '../../../../utils/URLUtils';
import Spinner from '../../../ui/Spinner/Spinner';
import DefinitionSection from './DefinitionSection/DefinitionSection';
import FinishModal from './FinishModal/FinishModal';
import PlaySection from './PlaySection/PlaySection';
import styles from './TextContent.module.scss';


interface TextContentProps {
    word: Word;
    wordData: WordData;
}

const TextContent: FC<TextContentProps> = ({ word, wordData }) => {
    const primarySense = wordData?.Senses?.find(sense => sense.id === word.PrimarySenseId);
    const { userInfo, isNonEnglishNativeLanguage } = useUser();
    const { isLoading, sendChain } = useFetch<string>();
    const [showFlag, setShowFlag] = useState(true);
    const [translateDefinition, setTranslateDefinition] = useState<string | null>(null);
    const [translateExample, setTranslateExample] = useState<string | null>(null);
    const { getTranslateBySenseId } = useWordTranslate(userInfo?.NativeLanguageCode);


    const handleFlagClick = useCallback(async () => {
        setShowFlag(false);
        const results =
            await sendChain([
                { url: GetRazorSiteUrl(`translation/phrase/${primarySense?.de}`, userInfo?.Token), responseType: ResponseType.Text },
                { url: GetRazorSiteUrl(`translation/phrase/${primarySense?.ex}`, userInfo?.Token), responseType: ResponseType.Text }
            ]);
        if (results) {
            setTranslateDefinition(results[0].response);
            setTranslateExample(results[1].response);
        }
    }, [sendChain, userInfo?.Token, primarySense]);

    useEffect(() => {
        if (!isNonEnglishNativeLanguage()) {
            setShowFlag(false);
        }
    }, [isNonEnglishNativeLanguage]);


    if (isLoading) return <Spinner />

    return (
        <div className={styles.textContentWrapper}>
            <div className={styles.textContent} >
                <PlaySection
                    type={primarySense?.ty}
                    typeClass={styles.type}
                    audioText={`${primarySense?.de}. for example. ${primarySense?.ex}`}
                    showFlag={showFlag}
                    nativeLanguageCode={userInfo?.NativeLanguageCode}
                    onFlagClick={handleFlagClick}
                />

                <DefinitionSection
                    definition={primarySense?.de}
                    example={primarySense?.ex}
                    translateDefinition={translateDefinition}
                    translateExample={translateExample}
                    wordText={word.Text}
                />
                <FinishModal finishText={getTranslateBySenseId(word?.PrimarySenseId)} />
            </div>
        </div>
    );
};

export default TextContent;