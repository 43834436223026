export const enum LanguageLevel {
    Basic = "Basic",
    Beginner = "Beginner",
    Intermediate = "Intermediate",
    Advanced = "Advanced",
    Fluent = "Fluent",
    Native = "Native"
}

export const enum Http {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export const enum Period {
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
}

export const enum CardBackgroundOption {
    VideoOrImage = "VideoOrImage",
    Image = "Image",
    Nothing = "Nothing",
}

export const enum Voice {
    gb = "gb",
    us = "us",
}

export const enum ContentType {
    Image = "Image",
    Video = "Video",
}

export const enum AnimationType {
    SlideRight = "slideRight",
    SlideUp = "slideUp",
    SlideDown = "slideDown",
    SlideLeft = "slideLeft",
    RotateRight = "rotateRight",
    RotateLeft = "rotateLeft",
    RotateIn = "rotateIn",
    RotateOut = "rotateOut",
}

export const enum MenuPosition {
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right",
    TopLeft = "top-left",
    TopRight = "top-right",
}

export const PathName = {
    learn: "/learn",
    practice: "/practice",
    progress: "/progress",
    wordlists: "/wordlists",
    lessons: "/lessons",
}

export const enum ResponseType {
    Json = "json",
    Text = "text",
}

export const enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export const enum LearnWizardStep {
    First = "FirstWizard",
    Second = "SecondWizard",
    Third = "ThirdWizard",
    Final = "FinalWizard",
}

export enum LearningArray {
    KnownWordIds = "KnownWordIds",
    UnknownWordIds = "UnknownWordIds",
    ReviewWordIds = "ReviewWordIds",
    ToLearn = "ToLearn",
    Lessons = "Lessons"
}

export enum Actions {
    Learn = "learn",
    Knew = "knew",
    Remembered = "remembered",
    Forgot = "forgot",
    AddSeconds = "add-seconds",
    ScheduleReview = "schedule-review",
}

export enum Direction {
    Prev = 'prev',
    Next = 'next'
}

export enum WordListType {
    Custom = 'custom',
    Subject = 'subject',
    All = 'all'
}

export enum LoadingStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed'
}