import { useState } from 'react';
import { Word } from '../../../../model/Word';
import { WordCard } from '../../../ui/GlobalComponents/WordCard/WordCard';
import styles from './WordListPageContent.module.scss';

interface WordListPageContentProps {
    words: Word[];
}

export const WordListPageContent = ({ words }: WordListPageContentProps) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredWords = words.filter((word) =>
        word.Text.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.wordListPageContent}>
            <div className={styles.searchContainer}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.searchInput}
                />
            </div>
            <div className={styles.wordList}>
                {filteredWords.map((word) => (
                    <WordCard key={word.ID} word={word} />
                ))}
            </div>
        </div>
    );
};