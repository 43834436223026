import { useEffect, useState } from 'react';
import { useLearning } from '../../hook/useLearning';
import { WordList } from '../../model/WordList';
import Spinner from '../ui/Spinner/Spinner';
import styles from './Discover.module.scss';
import { DiscoverItem } from './DiscoverItem/DiscoverItem';

const Discover = () => {
    const { learningInfo } = useLearning();
    const [completedWordLists, setCompletedWordLists] = useState<WordList[]>([]);
    const [unCompletedWordLists, setUnCompletedWordLists] = useState<WordList[]>([]);


    useEffect(() => {
        const wordlists = learningInfo?.WordLists;
        const complete = wordlists?.filter(wordlist => wordlist.IsCompleted);
        const unComplete = wordlists?.filter(wordlist => !wordlist.IsCompleted);
        setCompletedWordLists(complete || []);
        setUnCompletedWordLists(unComplete || []);
    }, [learningInfo]);


    if (learningInfo?.WordLists?.length === 0) return <Spinner />;

    return (
        <article className={styles.discover}>
            {unCompletedWordLists?.map((wordlist, index) => (
                <DiscoverItem key={index} wordlist={wordlist} />
            ))}
            <h2>Fully uncovered</h2>
            {completedWordLists?.map((wordlist, index) => (
                <DiscoverItem key={index} wordlist={wordlist} />
            ))}
            <button className='secondaryButton'>More lists...</button>
        </article>

    );

}

export default Discover;