import { useEffect, useState } from 'react';
import useFetch from 'src/hook/useFetch';
import useUser from 'src/hook/useUser';
import { useWordTranslate } from 'src/hook/useWordTranslate';
import { useIFrameModal } from '../../../hook/useIFrameModal';
import { GetRazorSiteUrl, IconLocation } from '../../../utils/URLUtils';
import styles from './IFrameModal.module.scss';

const IFrameModal = () => {
  const { isOpen, url, title, close } = useIFrameModal();
  const [hasNavigated, setHasNavigated] = useState(false);
  const { fetchUserData, userInfo, updateUserInfo } = useUser();
  const { fetchTranslates } = useWordTranslate(undefined, false);
  const { send } = useFetch<any>();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'NAVIGATION_COMPLETED') {
        setHasNavigated(true);
      } else if (event.data.type === 'NAVIGATION_BACK_COMPLETED') {
        setHasNavigated(false);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);



  const updateData = async () => {
    //   await fetchUserData();
    const { response } = await send(GetRazorSiteUrl('settings', userInfo?.Token));
    updateUserInfo(response);
    fetchTranslates(response.NativeLanguageCode);
  };

  const handleClose = async () => {
    const iframe = document.querySelector('iframe');
    if (iframe?.contentWindow && hasNavigated) {
      iframe.contentWindow.postMessage({ type: 'NAVIGATE_BACK' }, '*');
    } else {
      close();
      updateData();
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.iFrameModal}>
      <div className={styles.iframeHeader}>
        <img
          className={styles.closeButton}
          src={IconLocation('chevron-left')}
          alt="close"
          onClick={handleClose}
        />
        {!hasNavigated && <h1>{title}</h1>}
      </div>
      <div className={styles.iframeContainer}>
        <iframe
          src={url}
          title={title}
          allow="fullscreen"
          sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        />
      </div>
    </div>
  );
};

export default IFrameModal;